/******************************************************************************
 * App configuration
 *****************************************************************************/
export const APP_NAME = "Ableseportal Stadtwerke Kempen";
export const APP_SHORT_NAME = "Ableseportal";
export const APP_DESCRIPTION =
    "Melden Sie uns einfach online Ihre Zählerstände - schnell, bequem, sicher.";
export const CLIENT_FULL = "Stadtwerke Kempen GmbH";
export const CLIENT_SHORT = "Stadtwerke Kempen";
export const FALLBACK_PRECOMMA_DIGITS = 7;
export const URLS = {
    website: "https://www.stadtwerke-kempen.de",
    privacy: "https://www.stadtwerke-kempen.de/de/Home/Datenschutz/",
    imprint: "https://www.stadtwerke-kempen.de/de/Home/Impressum/",
};
export const LENGTH_IMPLAUSIBLE_REASON_OTHER = 200;
export const ERROR_MESSAGES = {
    error429: "😡 Zuviele Anfragen. Bitte versuchen Sie es in Kürze erneut.",
    error500:
        "😓 Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten. Bitte kontaktieren Sie unseren Kundenservice.",
};
export const CONTACT_CHANNELS = [
    {
        what: "email",
        icon: "email",
        label: "E-Mail-Adresse",
        inputmode: "email",
        length: 120,
    },
    {
        what: "phone",
        icon: "phone",
        label: "Telefon",
        inputmode: "tel",
        length: 30,
    },
    {
        what: "phoneMobile",
        icon: "phone_mobile",
        label: "Handy",
        inputmode: "tel",
        length: 30,
    },
    { what: "fax", icon: "fax", label: "Fax", inputmode: "tel", length: 30 },
];
